import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePresetAncientPeriod() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  
  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'traitCategoryType', sortable: true },
    { key: 'actions', class: 'text-right' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })
  
  const refetchData = () => {
    refListTable.value.refresh()
  }
  
  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  
  const fetchList = (ctx, callback) => {
    store
      .dispatch('traitCategoryManage/fetchAll', {
        Search: searchQuery.value,
        PageSize: perPage.value,
        PageIndex: currentPage.value,
        sortBy: sortBy.value,
        isSortDirDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { data, count } = response.result;
        
        callback(data)
        totalItems.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Trait Category list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchList,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
  
    refetchData,
  }
}
